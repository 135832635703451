import React from "react";
import {
  Box,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  ListItem,
  ListItemText,
} from "@mui/material";
import { IoMdArrowBack, IoMdCamera } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { FaRobot } from "react-icons/fa6";

const VisualAnalysisRequest = () => {
    const navigate = useNavigate();
  
  const goto = (path) => {
    navigate(`/${path}`);
  };
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Link to={"/"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">Visual Analysis</Typography>
        </Toolbar>
      </AppBar>
      <Card className="m-3 mt-14">
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px", fontWeight: 600 }}
                  component="h5"
                  variant="h5"
                  color="text.primary"
                >
                  Visual Analysis
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Capture Image/Video
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Toolbar>
          <Button sx={{'marginRight': 5}} onClick={() => goto("visual-data/create-request")} variant="contained" startIcon={<IoMdCamera />}>
            Capture
          </Button>
          <Button onClick={() => goto("visual-data/my-requests")} variant="contained">
            My Requests
          </Button>
        </Toolbar>
      </Card>
      {/* <Card className="m-3 mt-4">
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px", fontWeight: 600 }}
                  component="h5"
                  variant="h5"
                  color="text.primary"
                >
                  Video
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Record video from device camera
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Toolbar>
          <Button goto="visual-data/video"  variant="contained" startIcon={<IoMdVideocam />}>
            Capture a Video
          </Button>
        </Toolbar>
      </Card> */}
      <Card className="m-3 mt-4">
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px", fontWeight: 600 }}
                  component="h5"
                  variant="h5"
                  color="text.primary"
                >
                  Nurse Chat
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Chat with AI Nurse Assistant
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Toolbar>
          <Button onClick={() => goto("visual-data/nurse-chat")} variant="contained" startIcon={<FaRobot />}>
            Open Nurse Chat
          </Button>
        </Toolbar>
      </Card>      
    </Box>
  );
};

export default VisualAnalysisRequest;
